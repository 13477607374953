import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Hero from "./pages/hero/Hero";
import NotFound from "./pages/notfound/NotFound";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* <Route path="/portfolio" component={Portfolio} /> */}
          {/* <Route path="/website" component={Website} /> */}
          <Route path="/" exact component={Hero} />
          {/* Route for 404 not found */}
          <Route path="/not-found" component={NotFound} />
          {/* Catch- all route for unmatched routes */}
          <Route path="*" render={() => <Redirect to="/not-found" />} />
        </Switch>
      </Router>
    );
  }
}

export default App;

import React from "react";
import "./NotFound.scss";

export default function NotFound() {
  return (
    <div>
      <div class="column">
        <div class="white"></div>
        <div class="black"></div>
        <div class="light-yellow"></div>
        <div class="light-yellow-hover"></div>
        <div class="tan"></div>
        <div class="tan-hover"></div>
        <div class="light-gray"></div>
        <div class="medium-gray"></div>
        <div class="dark-gray"></div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaEnvelope,
  FaHamburger,
  FaInstagram,
  FaLinkedin,
  FaMusic,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import "./Hero.scss";
import AboutMe from "../aboutme/AboutMe";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import Acheivement from "../acheivement/Acheivement";
import Resume from "../resume/Resume";
import Project from "../project/Project";
import Performance from "../performance/Performance";

const socialLinks = [
  {
    href: "mailto:hello.nilisha@gmail.com",
    icon: <FaEnvelope />,
    className: "email",
  },
  {
    href: "https://instagram.com/nilishasingh_",
    icon: <FaInstagram />,
    className: "instagram",
  },
  {
    href: "https://www.youtube.com/@nilishasinghh",
    icon: <FaYoutube />,
    className: "youtube",
  },
  {
    href: "https://www.linkedin.com/in/nilishasingh",
    icon: <FaLinkedin />,
    className: "linkedin",
  },
  {
    href: "https://www.tiktok.com/@nilishasingh_",
    icon: <FaTiktok />,
    className: "tiktok",
  },
];

const navLinks = [
  { to: "/", label: "Home", section: "home" },
  { to: "#about", label: "About", section: "about" },
  { to: "#achievements", label: "Achievements", section: "achievements" },
  { to: "#resume", label: "Resume", section: "resume" },
  { to: "#projects", label: "Projects", section: "projects" },
  { to: "#performances", label: "Performances", section: "performances" },
];

export default function Hero() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992);

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    };
  }, []);

  const handleNavClick = (section) => {
    setIsOpen(false);
    if (section === "home" && isClicked === false) {
      setIsClicked(false);
      setActiveSection("");
    } else {
      setIsClicked(true);
      setActiveSection(section);
      if (section === "home" && isClicked) {
        setIsClicked(false);
        setActiveSection("home");
      }
    }
  };

  return (
    <div id="layout">
      <header id="header" className={isClicked ? "clicked" : ""}>
        <div className="container">
          <div className="header-content">
            <h1>Nilisha Singh</h1>
            <h2 id="the-subtitle">
              I'm a passionate <span>Learner</span> &amp; <span>Violinist</span>
              . Undergraduate Student.
            </h2>
          </div>

          <nav id="navbar" className={`${isOpen ? "open" : ""} navbar`}>
            <div
              className={`ham-icon ${isOpen ? "open" : ""}`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <RxHamburgerMenu />
            </div>
            {isLargeScreen || isOpen ? (
              <ul>
                <div className="close-icon" onClick={() => setIsOpen(false)}>
                  <RxCross2 />
                </div>
                {navLinks.map(({ to, label, section }) => (
                  <li key={section}>
                    <Link
                      className={`nav-link ${
                        activeSection === section ? "active" : ""
                      }`}
                      to={to}
                      onClick={() => handleNavClick(section)}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </nav>

          <div className="social-links">
            {socialLinks.map(({ href, icon, className }) => (
              <a
                key={className}
                href={href}
                className={className}
                target="_blank"
                rel="noopener noreferrer"
              >
                {icon}
              </a>
            ))}
          </div>
        </div>
      </header>
      {activeSection !== "home" ? (
        <section id="content">
          {activeSection === "about" && <AboutMe />}
          {activeSection === "achievements" && <Acheivement />}
          {activeSection === "resume" && <Resume />}
          {activeSection === "projects" && <Project />}
          {activeSection === "performances" && <Performance />}
        </section>
      ) : (
        ""
      )}
    </div>
  );
}
